import styles from './ImageGradientOverlay.module.scss'

interface Props {}

const ImageGradientOverlay = ({}: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.top} />
      <div className={styles.bottom} />
    </div>
  )
}

export default ImageGradientOverlay
