import styles from './ResponsiveHeaderImage.module.scss'
import React from 'react'
// import CloudinaryImageSrcSet from '@/components/elements/media/CloudinaryImageSrcSet'
import CloudinaryImage from '@/components/elements/media/CloudinaryImage'
import ImageGradientOverlay from '@/components/elements/ImageGradientOverlay'
import mediaFileObject from '@/entities/mediaFile/interface'
import useBreakpoint from '@/hooks/useBreakpoint'

interface Props {
  image: mediaFileObject
  lazyload?: boolean // for better LCP, the initial visible image (e.g. first slider image) should not be lazy loaded
  withSidebar?: boolean // some pages have a sidebar. The image will be loaded in optimized aspect ratio
}

const versions = [
  { name: 'xs', height: 750, width: 576, aspectRatio: undefined },
  { name: 'sm', height: 750, width: 768, aspectRatio: undefined },
  { name: 'md', height: 800, width: 922, aspectRatio: undefined },
  { name: 'lg', height: 800, width: 1200, aspectRatio: 1.125 },
  { name: 'xl', height: 750, width: 1400, aspectRatio: 1.315 },
  { name: 'xxl', height: 800, width: 1650, aspectRatio: 1.6 },
  { name: 'fhd', height: 750, width: 1920, aspectRatio: 1.821 },
  { name: 'uw', height: 750, width: 2560, aspectRatio: 2.45 },
]

const isDefault = (currentBreakpoint: string | undefined, imageBreakpoint: string) => {
  if (currentBreakpoint) {
    return false
  }
  if (imageBreakpoint === 'xs') {
    return true
  }
}

// const buildSet = (versions: { name: string; height: number; width: number; aspectRatio: number | undefined }[], withSidebar) => {
//   const set = versions.map((version) => {
//     return {
//       breakpoint: version.name,
//       height: version.height,
//       width: withSidebar && version.aspectRatio ? undefined : version.width,
//       aspectRatio: withSidebar && version.aspectRatio ? version.aspectRatio : undefined,
//     }
//   })
//   return set
// }

const ResponsiveHeaderImage = ({ image, lazyload = false, withSidebar = false }: Props) => {
  const currentBreakpoint = useBreakpoint()
  return (
    <div className={styles.root}>
      {/*<CloudinaryImageSrcSet image={image} setOptions={buildSet(versions, withSidebar)} lazyload={lazyload} />*/}
      {versions.map((version, index: number) => {
        return (
          <React.Fragment key={index}>
            {(currentBreakpoint === version.name || isDefault(currentBreakpoint, version.name)) && (
              <CloudinaryImage
                key={index}
                image={image}
                height={version.height}
                width={withSidebar && version.aspectRatio ? undefined : version.width}
                aspectRatio={withSidebar && version.aspectRatio ? version.aspectRatio : undefined}
                lazyload={lazyload}
                responsive={false}
                placeholder={false}
              />
            )}
          </React.Fragment>
        )
      })}
      <ImageGradientOverlay />
    </div>
  )
}

export default ResponsiveHeaderImage
