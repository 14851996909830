import styles from './TextWithIconBlock.module.scss'
import { Col, Row } from 'react-bootstrap'
import CloudinaryImage from '@/components/elements/media/CloudinaryImage'

interface Props {
  title?: string
  text: string
  image: any
}

const TextWithIconBlock = ({ title = '', text = '', image }: Props) => {
  return (
    <Row className={styles.root}>
      <Col xs={12} sm={'auto'} lg={12} xl={'auto'}>
        <div className={`${styles.iconWrap}`}>
          <CloudinaryImage
            image={image}
            altDefault={'icon'}
            width={110}
            height={110}
            responsive={false}
            sizeAttributes={true}
            aspectRatio={1}
            placeholderClassName={'position-absolute'}
          />
        </div>
      </Col>
      <Col className={styles.content}>
        {title !== '' && <div className={styles.cardTitle}>{title}</div>}
        <div dangerouslySetInnerHTML={{ __html: text }} className={'display-linebreak'} />
      </Col>
    </Row>
  )
}

export default TextWithIconBlock
