import { Cloudinary } from '@cloudinary/url-gen'

const cloudinaryUrlGenerator = new Cloudinary({
  cloud: {
    cloudName: process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
  },
  url: {
    analytics: false,
  },
})

export default cloudinaryUrlGenerator
