import { useEffect, useState } from 'react'
import FsLightbox from 'fslightbox-react'
import mediaFileObject from '@/entities/mediaFile/interface'
import cloudinaryUrlGenerator from '@/integrations/cloudinary/cloudinaryUrlGenerator'
import { limitFill } from '@cloudinary/url-gen/actions/resize'
const cld = cloudinaryUrlGenerator

interface Props {
  images: Array<mediaFileObject>
  open?: boolean
  currentSlideIndex?: number
}

const GalleryLightBox = ({ images, open = false, currentSlideIndex = 0 }: Props) => {
  const [toggler, setToggler] = useState(open)
  const [slide, setSlide] = useState(currentSlideIndex)

  useEffect(() => {
    if (open != toggler) {
      setToggler(!toggler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    if (Number.isInteger(currentSlideIndex)) {
      setSlide(currentSlideIndex)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlideIndex])

  const lightboxImages: string[] = images.map((image) => {
    const myImage = cld.image(image.publicId)
    myImage.format('auto').quality('auto')
    myImage.resize(limitFill().height(1080))
    return myImage.toURL()
  })
  return (
    <>
      <FsLightbox toggler={toggler} sources={lightboxImages} slide={slide + 1} />
    </>
  )
}

export default GalleryLightBox
