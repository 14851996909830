import styles from '@/components/elements/sliders/common/DotButton/DotButton.module.scss'

const DotButton = ({ selected, onClick, theme }: any) => (
  <button
    className={styles.embla__dot + ' ' + styles[theme] + ` ${selected ? styles.isSelected : ''}`}
    type="button"
    onClick={onClick}
    aria-label={'Slider navigation'}
  />
)

export default DotButton
