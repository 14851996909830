import Head from 'next/head'
import elementSeoSettingsObject from '@/entities/elementSeoSettings/interface'
import { useRouter } from 'next/router'
import cloudinaryUrlGenerator from '@/integrations/cloudinary/cloudinaryUrlGenerator'
import { fill } from '@cloudinary/url-gen/actions/resize'

interface Props {
  seoSettings: elementSeoSettingsObject
  noIndex?: boolean
}
const SeoHeader = ({ seoSettings, noIndex = false }: Props) => {
  // build canonicalUrl
  const router = useRouter()
  const canonicalUrl = (
    process.env.NEXT_PUBLIC_BASE_URL +
    (router.locale ? '/' + router.locale : '') +
    (router.route === '/404' || router.route === '/500' ? router.route : router.asPath !== '/' ? router.asPath : '')
  ).split('?')[0]

  // build optimized image url
  const cld = cloudinaryUrlGenerator
  const ogImage = cld.image(seoSettings.ogImage.publicId)
  const cropSettings = fill()
  cropSettings.width(1200)
  cropSettings.height(630)
  cropSettings.gravity('auto')
  ogImage.resize(cropSettings).format('auto').quality('auto')
  const ogImageUrl = ogImage.toURL()

  const twitterImage = cld.image(seoSettings.ogImage.publicId)
  cropSettings.width(1200)
  cropSettings.height(600)
  cropSettings.gravity('auto')
  twitterImage.resize(cropSettings).format('auto').quality('auto')
  const twitterImageUrl = twitterImage.toURL()

  return (
    <Head>
      <title>{seoSettings.metaTitle}</title>
      <meta name="description" content={seoSettings.metaDescription} />
      <meta property="og:title" content={seoSettings.ogTitle} />
      <meta property="og:description" content={seoSettings.ogDescription} />
      {seoSettings.ogImage && <meta property="og:image" content={ogImageUrl} />}
      <meta name="twitter:title" content={seoSettings.ogTitle} />
      <meta name="twitter:description" content={seoSettings.ogDescription} />
      {seoSettings.ogImage && <meta name="twitter:image" content={twitterImageUrl} />}
      <link rel="canonical" href={canonicalUrl} />
      {noIndex && <meta name="robots" content="noindex" />}
    </Head>
  )
}

export default SeoHeader
