import { useEffect, useState } from 'react'

export const breakpoints = [
  {
    name: 'uw',
    minWidth: 1930,
  },
  {
    name: 'fhd',
    minWidth: 1700,
  },
  {
    name: 'xxl',
    minWidth: 1400,
  },
  {
    name: 'xl',
    minWidth: 1200,
  },
  {
    name: 'lg',
    minWidth: 992,
  },
  {
    name: 'md',
    minWidth: 768,
  },
  {
    name: 'sm',
    minWidth: 576,
  },
  {
    name: 'xs',
    minWidth: 0,
  },
]

export default function useBreakpoint() {
  const [breakpoint, setBreakpoint] = useState<undefined | string>(undefined)
  function handleResize() {
    if (typeof window !== 'undefined') {
      for (let i = 0; i < breakpoints.length; i++) {
        if (window.innerWidth >= breakpoints[i].minWidth) {
          setBreakpoint(breakpoints[i].name)
          break
        }
      }
    }
  }

  useEffect(() => {
    // only execute all the code below in client side
    if (typeof window !== 'undefined') {
      // Add event listener
      window.addEventListener('resize', handleResize)

      // Call handler right away so state gets updated with initial window size
      handleResize()

      // Remove event listener on cleanup
      return () => window.removeEventListener('resize', handleResize)
    }
  }, []) // Empty array ensures that effect is only run on mount

  return breakpoint
}
