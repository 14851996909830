import CloudinaryImage from '@/components/elements/media/CloudinaryImage'
import { CloudinaryImageProps } from '@/components/elements/media/CloudinaryImage/CloudinaryImage'
import { Cloudinary } from '@cloudinary/url-gen'
import mediaFileObject from '@/entities/mediaFile/interface'

const cloudinaryUrlGenerator = new Cloudinary({
  cloud: {
    cloudName: process.env.NEXT_PUBLIC_CLOUDINARY_THEME_CLOUD_NAME,
  },
  url: {
    analytics: false,
  },
})

// @ts-ignore
interface Props extends CloudinaryImageProps {
  image?: mediaFileObject
  src: string
  title?: string
  alt?: string
  dpr?: boolean | number
  theme?: string
  style?: object
}

const ThemeImage = (props: Props) => {
  let cropType = props.cropType
  if (!props.cropType) {
    cropType = 'scale'
  }
  const responsive = props.responsive !== undefined ? props.responsive : false
  const theme = props.theme ? props.theme : process.env.NEXT_PUBLIC_THEME
  const image = props.image
    ? props.image
    : ({
        publicId: `themes/${theme}/${props.src}`,
        title: props.title,
        description: props.alt,
      } as mediaFileObject)

  return (
    <CloudinaryImage
      {...props}
      image={image}
      responsive={responsive}
      cropType={cropType}
      dpr={props.dpr !== undefined ? props.dpr : false}
      cld={cloudinaryUrlGenerator}
      style={props.style ? props.style : {}}
      sizeAttributes={true}
    />
  )
}

export default ThemeImage
