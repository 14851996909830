import styles from './Accordion.module.scss'
import BsAccordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import { useState } from 'react'

interface AccordionItemProps {
  title: string | JSX.Element
  content: string | JSX.Element
}

interface Props {
  items: Array<AccordionItemProps>
  variant?: '' | 'unstyledWhite'
  className?: string
  collapsed?: boolean
}

function CustomToggle({ children, eventKey }: any) {
  const [active, setActive] = useState(false)
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    setActive(!active)
  })

  return (
    <button type="button" className={`${active && styles.active} ${styles.accordionButton}`} onClick={decoratedOnClick}>
      {children}
    </button>
  )
}

const Accordion = ({ items, variant = '', className, collapsed }: Props) => {
  const getDefaultActiveKey = (index: number) => {
    if (collapsed === false) {
      return `${index}`
    }
    return items.length === 1 ? '0' : '99'
  }

  return (
    <div className={`${styles.root} ${className as string} ${styles[variant]}`}>
      {items.map((item: AccordionItemProps, index: number) => (
        <BsAccordion key={index} className={styles.accordion} defaultActiveKey={getDefaultActiveKey(index)}>
          <Card className={styles.accordionItem}>
            <Card.Header className={styles.accordionHeader}>
              <CustomToggle eventKey={`${index}`}>{item.title}</CustomToggle>
            </Card.Header>
            <BsAccordion.Collapse eventKey={`${index}`}>
              {typeof item.content === 'string' ? (
                <Card.Body className={styles.cardBody} dangerouslySetInnerHTML={{ __html: item.content }} />
              ) : (
                <Card.Body className={`${styles.cardBody} display-linebreak`}>{item.content}</Card.Body>
              )}
            </BsAccordion.Collapse>
          </Card>

          {/*<BsAccordion.Item eventKey="0" >*/}
          {/*  <BsAccordion.Header className={styles.accordionButton}>{item.label}</BsAccordion.Header>*/}
          {/*  <BsAccordion.Body></BsAccordion.Body>*/}
          {/*</BsAccordion.Item>*/}
        </BsAccordion>
      ))}
    </div>
  )
}

export default Accordion
