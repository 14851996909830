import { createContext } from 'react'
export const PrintConfigContext = createContext<any>({
  isPrint: false,
  images: {
    lazyloadEnabled: true,
  },
})

const PrintConfigProvider = ({ children }: any) => {
  return (
    <PrintConfigContext.Provider
      value={{
        isPrint: true,
        images: {
          lazyloadEnabled: false,
        },
      }}
    >
      {children}
    </PrintConfigContext.Provider>
  )
}

export default PrintConfigProvider
