import React, { useState, useEffect, useCallback } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import styles from './TextWithImageSlider.module.scss'
import { useInViewport } from 'react-in-viewport'
import DotButton from '@/components/elements/sliders/common/DotButton'
import imageGalleryObject from '@/entities/imageGallery/interface'
import CloudinaryImage from '@/components/elements/media/CloudinaryImage'
import GalleryLightBox from '@/components/models/media/GalleryLightBox'

interface Props {
  gallery: imageGalleryObject
}

const TextWithImageSlider = ({ gallery }: Props) => {
  /** SLIDES **/
  const [lightboxOpen, setLightboxOpen] = useState<boolean>(false)
  const [lightboxCurrentSlide, setLightboxCurrentSlide] = useState<number>()

  const handleLightbox = (event: MouseEvent, index: number) => {
    if (event.detail === 2) {
      setLightboxCurrentSlide(index)
      setLightboxOpen(!lightboxOpen)
    }
  }

  const getSlides = (images: Array<any>) => {
    let slides: Array<any> = []
    images.map((image: any, index: number) => {
      const slide = (
        <div className={styles.embla__slide} onClick={(event) => handleLightbox(event as unknown as MouseEvent, index)} key={index}>
          <div className="box-ratio-16-9">
            <div className="box-ratio-inner">
              <CloudinaryImage image={image} width={704} aspectRatio={1.777} responsive={false} className={`card-img d-lg-none ${styles.img}`} />
              <CloudinaryImage
                image={image}
                width={704}
                aspectRatio={0.8}
                responsive={false}
                className={`card-img d-none d-lg-block d-xl-none ${styles.img}`}
              />
              <CloudinaryImage
                image={image}
                width={704}
                aspectRatio={1.5}
                responsive={false}
                className={`card-img d-none d-xl-block ${styles.img}`}
              />
            </div>
          </div>
        </div>
      )
      slides.push(slide)
    })
    return slides
  }

  /** EMBLA **/
  const options: any = { loop: true, skipSnaps: true }
  const dotTheme = 'light'

  //autoplay
  let plugins = []
  const autoplayRoot = (emblaRoot: any) => emblaRoot.parentElement // Root node
  const autoplayPlugin = Autoplay({ delay: 5000, rootNode: autoplayRoot })
  plugins.push(autoplayPlugin)

  const [emblaRef, embla] = useEmblaCarousel(options, plugins)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([])
  const scrollTo = useCallback((index: number) => embla && embla.scrollTo(index), [embla])

  const onSelect = useCallback(() => {
    if (!embla) return
    setSelectedIndex(embla.selectedScrollSnap())
  }, [embla, setSelectedIndex])

  // rerender for correct dots (lazy loaded images)
  const myRef = React.createRef<HTMLDivElement>()
  const { inViewport } = useInViewport(myRef, options, { disconnectOnLeave: false })
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!embla) return
      if (inViewport) {
        embla.reInit()
        setScrollSnaps(embla.scrollSnapList())
      }
    }, 500)
    return () => clearTimeout(timer)
  }, [embla, inViewport])

  useEffect(() => {
    if (!embla) return
    onSelect()
    setScrollSnaps(embla.scrollSnapList())
    embla.on('select', onSelect)
  }, [embla, setScrollSnaps, onSelect])

  return (
    <>
      <div className={styles.root}>
        <div className={`${styles.embla}`} ref={emblaRef}>
          <div className={styles.embla__container}>{getSlides(gallery.images).map((slide: any) => slide)}</div>
        </div>
        <div className={styles.embla__dots + ' ' + styles.bottom}>
          {scrollSnaps.map((slide: any, index: number) => (
            <DotButton key={index} selected={index === selectedIndex} theme={dotTheme} onClick={() => scrollTo(index)} />
          ))}
        </div>
        <GalleryLightBox images={gallery.images} open={lightboxOpen} currentSlideIndex={lightboxCurrentSlide} />
      </div>
    </>
  )
}

export default TextWithImageSlider
